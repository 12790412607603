import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.NEXT_PUBLIC_HOST_API;
export const ASSETS_API = process.env.NEXT_PUBLIC_ASSETS_API;
export const BOT_ID = process.env.NEXT_PUBLIC_BOT_ID || 500;
export const HOST_API_KEY = process.env.NEXT_PUBLIC_HOST_API_KEY;
export const HOST_API_LEGACY_KEY = process.env.NEXT_PUBLIC_HOST_API_LEGACY_KEY;
export const HOST_MEDIA_KEY = process.env.NEXT_PUBLIC_HOST_MEDIA_KEY;
export const RAAYONIT_BOT_ID = process.env.NEXT_PUBLIC_RAAYONIT_BOT_ID;
export const CONNECTION_TOKEN = process.env.NEXT_PUBLIC_CONNECTION_TOKEN;
export const MERCHANT_ID = process.env.NEXT_PUBLIC_MERCHANT_ID;
export const ACTIVITY_ID = process.env.NEXT_PUBLIC_ACTIVITY_ID;

export const XTRA_API_KEY = process.env.NEXT_PUBLIC_XTRA_API_KEY;
export const RAAYONIT_API_KEY = process.env.NEXT_PUBLIC_RAAYONIT_API_KEY;

export const FIREBASE_API = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.NEXT_PUBLIC_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  callbackUrl: process.env.NEXT_PUBLIC_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.NEXT_PUBLIC_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

const getProjectName = () => {
  if (process.env.NEXT_PUBLIC_PROJECT_NAME) {
    const str = process.env.NEXT_PUBLIC_PROJECT_NAME;
    const capitalizedStr = `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
    return capitalizedStr;
  }
  return 'B24Chat CRM';
};

export const PROJECT_NAME = getProjectName();

const getLogoPath = () => {
  const defaultPath = {
    full: '/logo/B24_logo.png',
    small: '/logo/B24_logo.png',
  };
  const retrieveUrl = () => {
    switch (PROJECT_NAME.toLowerCase()) {
      case 'xtra':
        return {
          full: '/logo/xtra/logo-single.svg',
          small: '/logo/xtra/logo.svg',
        };
      case 'ksharim':
        return {
          full: '/logo/ksharim/logo_happygift.svg',
          small: '/logo/ksharim/logo_happygift_little.svg',
        };
      case 'raayonit':
        return {
          full: '/logo/raayonit/raayonit_horizontal.png',
          small: '/logo/raayonit/raayonit_vertical.png',
        };
      case 'boombuy':
        return {
          full: '/logo/boom-buy/boom-logo-colored.png',
          small: '/logo/boom-buy/boom-logo-colored.png',
        };
      case 'citypeople':
        return {
          full: '/logo/city-people/logo.svg',
          small: '/logo/city-people/logo.svg',
        };
      case 'hashavshevet':
        return {
          full: '/logo/hashavshevet/logo.svg',
          small: '/logo/hashavshevet/logo.svg',
        };
      default:
        return defaultPath;
    }
  };

  return { ...retrieveUrl(), defaultPath };
};

export const LOGO_PATH = getLogoPath();
